import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Icon, theme } from '@damen/ui'
import { Props, Slide as ISlide } from './types'
import {
	Buttons,
	Content,
	Controls,
	Indicator,
	Progress,
	ProgressBar,
	SlideWrapper,
	SlidesWrapper,
	StyledContainer,
	Title,
	TitleWrapper,
	Wrapper,
	StyledSlickCarousel,
	MobileControls
} from './styles'

const Slider: React.FC<Props> = ({ children, title }) => {
	const isMobile = useRef(false)
	const isTablet = useRef(false)
	const sliderRef = useRef(null)
	const [position, setPosition] = useState(3)
	const [progress, setProgress] = useState(0)
	const [rendering, setRendering] = useState(true)
	// TODO It is not ideal to implement direction like this but a useRef caused the
	// states for example for progress to not update. This is best as we can get it for now.
	// This also prevented from going back to the previous slide via the prev button
	let direction = 'right'

	const sliderSettings = {
		dots: false,
		slidesToShow: 3,
		variableWidth: true,
		slidesToScroll: 1,
		arrows: false,
		adaptiveHeight: false,
		autoplay: false,
		infinite: false,
		beforeChange: (current: number, next: number) => {
			if (current === next || direction === 'reset') {
				direction = 'reset'
			} else if (current < next) {
				direction = 'right'
			} else {
				direction = 'left'
			}
		},
		afterChange: () => {
			if (direction !== 'reset') {
				if (direction === 'left') {
					updatePrevStates()
				} else {
					updateNextStates()
				}
			}
		},
		responsive: [
			{
				breakpoint: theme.breakpointsNumerical.lg,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: theme.breakpointsNumerical.sm,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

	const updateNextStates = () => {
		if (isMobile.current) {
			setProgress(((position - 1) / children.length) * 100)
		} else if (isTablet.current) {
			setProgress((position / children.length) * 100)
		} else {
			setProgress(((position + 1) / children.length) * 100)
		}
		setPosition((currentValue) => currentValue + 1)
	}

	const updatePrevStates = () => {
		if (isMobile.current) {
			setProgress(((position - 3) / children.length) * 100)
		} else if (isTablet.current) {
			setProgress(((position - 2) / children.length) * 100)
		} else {
			setProgress(((position - 1) / children.length) * 100)
		}
		setPosition((currentValue) => currentValue - 1)
	}

	const gotoNextSlide = () => {
		sliderRef?.current?.slickNext()
	}
	const gotoPrevSlide = () => {
		sliderRef?.current?.slickPrev()
	}

	const resetState = () => {
		direction = 'reset'
		setPosition(3)
		sliderRef?.current?.slickGoTo(0)

		if (isMobile.current) {
			return setProgress(((position - 2) / children.length) * 100)
		}

		if (isTablet.current) {
			return setProgress(((position - 1) / children.length) * 100)
		}

		return setProgress((3 / children.length) * 100)
	}

	const handleResize = () => {
		const { innerWidth } = window

		if (innerWidth < theme.breakpointsNumerical.sm && !isMobile.current) {
			isMobile.current = true
		}

		if (innerWidth > theme.breakpointsNumerical.sm && isMobile.current) {
			isMobile.current = false
		}

		if (innerWidth < theme.breakpointsNumerical.lg && !isMobile.current) {
			isTablet.current = true
		}

		if (innerWidth > theme.breakpointsNumerical.lg && isMobile.current) {
			isTablet.current = false
		}

		return null
	}

	const nextButtonDisabled = () => {
		if (isMobile.current) {
			return position - 2 === children.length
		}
		if (isTablet.current) {
			return position - 1 === children.length
		}
		return position === children.length
	}

	useEffect(() => {
		handleResize()

		setTimeout(() => {
			setRendering(false)
			handleResize()
		}, 300)

		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		resetState()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children])

	if (rendering) return null

	return (
		<Wrapper>
			<StyledContainer>
				<Content isMobile={isMobile.current}>
					{title && (
						<TitleWrapper>
							<Title>{title}</Title>
							{children.length > 3 && (
								<Controls>
									<Progress>
										<ProgressBar>
											<Indicator percentage={progress} />
										</ProgressBar>
									</Progress>
									<Buttons>
										<Icon.ArrowLeft
											disabled={position === 3}
											fill={theme.colors.white}
											height={40}
											width={40}
											onClick={gotoPrevSlide}
										/>
										<Icon.ArrowRight
											disabled={nextButtonDisabled()}
											fill={theme.colors.white}
											height={40}
											width={40}
											onClick={gotoNextSlide}
										/>
									</Buttons>
								</Controls>
							)}
						</TitleWrapper>
					)}
					<SlidesWrapper>
						{/* eslint-disable @typescript-eslint/ban-ts-comment */}
						{/** @ts-ignore @ant-design/react-slick types haven't been updated to support react18 yet */}
						<StyledSlickCarousel
							{...sliderSettings}
							ref={sliderRef}
						>
							{children.map((slide: ISlide) => {
								return (
									<SlideWrapper>
										{slide as ReactNode}
									</SlideWrapper>
								)
							})}
						</StyledSlickCarousel>

						{children.length > 1 && (
							<MobileControls>
								<Progress>
									<ProgressBar>
										<Indicator percentage={progress} />
									</ProgressBar>
								</Progress>
								<Buttons>
									<Icon.ArrowLeft
										disabled={position === 3}
										fill={theme.colors.white}
										height={40}
										width={40}
										onClick={gotoPrevSlide}
									/>
									<Icon.ArrowRight
										disabled={
											isMobile.current
												? position - 2 ===
												  children.length
												: position === children.length
										}
										fill={theme.colors.white}
										height={40}
										width={40}
										onClick={gotoNextSlide}
									/>
								</Buttons>
							</MobileControls>
						)}
					</SlidesWrapper>
				</Content>
			</StyledContainer>
		</Wrapper>
	)
}

export default Slider
