import styled, { css } from 'styled-components'
import Link from 'next/link'

import { ACTION } from '@src/constants/zIndexes'

interface AlignmentProps {
	alignment?: 'left'
	verticalAlignment?: 'bottom'
}

export const ActionsList = styled.ul<AlignmentProps>`
	pointer-events: none;
	position: absolute;
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: ${({ theme }) => theme.shadows.whiteOnWhite.three};
	transition: opacity 0.2s, transform 0.2s;
	opacity: 0;

	@media ${({ theme }) => theme.breakpoints.sm} {
		top: 0;
		width: 280px;

		${({ alignment, verticalAlignment }) => {
			if (alignment === 'left' && !verticalAlignment) {
				return css`
					right: auto;
					left: 0;
					transform: translateX(-100%);
				`
			}

			if (verticalAlignment === 'bottom' && alignment === 'left') {
				return css`
					top: calc(100%);
					right: 0;
					left: auto;
				`
			}

			if (
				verticalAlignment === 'bottom' &&
				(alignment !== 'left' || !alignment)
			) {
				return css`
					top: calc(100%);
					right: auto;
					left: 0;
				`
			}

			return css`
				right: 0;
				left: auto;
				transform: translateX(100%);
			`
		}}
	}

	@media ${({ theme }) => theme.breakpoints.smMax} {
		bottom: 0;
		left: 0;
		width: 280px;
		transform: translateY(100%);
	}
`

const FocusStyles = css<AlignmentProps>`
	pointer-events: all;
	opacity: 1;
	z-index: ${ACTION};

	@media ${({ theme }) => theme.breakpoints.sm} {
		${({ alignment, verticalAlignment }) => {
			if (alignment === 'left' && !verticalAlignment) {
				return css`
					transform: translateX(calc(-100% - 24px));
				`
			}

			if (verticalAlignment === 'bottom' && alignment === 'left') {
				return css`
					transform: translateX(0) translateY(24px);
				`
			}

			if (
				verticalAlignment === 'bottom' &&
				(alignment !== 'left' || !alignment)
			) {
				return css`
					transform: translateX(0) translateY(24px);
				`
			}

			return css`
				transform: translateX(calc(100% + 24px));
			`
		}}
	}

	@media ${({ theme }) => theme.breakpoints.smMax} {
		transform: translateY(calc(100% + 24px));
	}
`

export const Wrapper = styled.div<AlignmentProps>`
	display: inline-block;
	position: relative;

	> button:focus {
		pointer-events: all;

		+ ${ActionsList} {
			${FocusStyles}
		}
	}

	${ActionsList} {
		&:focus-within {
			${FocusStyles}
		}
	}
`

export const Action = styled.li`
	&:not(:last-child) a span,
	&:not(:last-child) button span {
		border-bottom: 1px solid ${({ theme }) => theme.colors.greyAccentLight};
	}
`

export const LinkItemWrapper = styled.span`
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.blue};
	padding-bottom: 20px;

	svg {
		flex: 0 1 auto;
		margin-right: 14px;

		path {
			fill: ${({ theme }) => theme.colors.blue};
		}
	}
`

const LinkStyles = css`
	display: block;
	padding: 20px 30px 0;
	font-size: ${({ theme }) => theme.typography.fontSizeTextSmall}px;
	font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
	line-height: 30px;
	letter-spacing: 0.44px;
	cursor: pointer;
	&:hover {
		background-color: ${({ theme }) => theme.colors.blueIce};
	}
`

export const ButtonAction = styled.button`
	${LinkStyles}
	width: 100%;
	background-color: transparent;
	border: none;
`

export const LinkAction = styled(Link)`
	${LinkStyles}
`
