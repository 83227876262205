import styled, { css } from 'styled-components'
import { theme } from '@damen/ui'
// Slick is added as a dependency with a newer version than in the component library
// We cannot use a Slider from the component library in MyDamen until the versions match
import SlickCarousel from '@ant-design/react-slick'
import { hexToRgb } from '@utils/helper'

// Added slick css manually since the import(import 'slick-carousel/slick/slick.css') breaks in the frontend.
// https://github.com/vercel/next.js/issues/19936

// slick-carousel/1.8.1/slick.css
export const slickStyles = css`
	/* Slider */
	.slick-slider {
		position: relative;

		display: block;
		box-sizing: border-box;
		user-select: none;

		-webkit-touch-callout: none;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;

		display: block;
		overflow: hidden;

		margin: 0;
		padding: 0;
	}
	.slick-list:focus {
		outline: none;
	}
	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;

		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.slick-track:before,
	.slick-track:after {
		display: table;

		content: '';
	}
	.slick-track:after {
		clear: both;
	}
	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		transform: translate3d(0, 0, 0);
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}
	[dir='rtl'] .slick-slide {
		float: right;
	}
	.slick-slide img {
		display: block;
	}
	.slick-slide.slick-loading img {
		display: none;
	}
	.slick-slide.dragging img {
		pointer-events: none;
	}
	.slick-initialized .slick-slide {
		display: block;
	}
	.slick-loading .slick-slide {
		visibility: hidden;
	}
	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}
	.slick-arrow.slick-hidden {
		display: none;
	}
`

export const SlideWrapper = styled.div`
	position: relative;
	display: block;
`

export const Wrapper = styled.div`
	display: flex;
	width: 100%;

	@media ${theme.breakpoints.lg} {
		margin-bottom: 42px;
	}
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 16px;
	font-family: ${theme.fonts.body};
	width: 100%;

	@media ${theme.breakpoints.md} {
		width: 100%;
		box-sizing: border-box;
	}
`

export const Title = styled.h2`
	font-size: ${theme.typography.fontSizeHeadingThreeMobile}px;

	@media (min-width: 706px) {
		margin-right: 16px;
	}

	@media ${theme.breakpoints.lg} {
		font-size: ${theme.typography.fontSizeHeadingThree}px;
		margin-right: 104px;
	}
`

export const TitleWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: 32px;

	@media (min-width: 706px) {
		align-items: center;
	}

	@media ${theme.breakpoints.lg} {
		margin-bottom: 48px;
	}
`

export const Content = styled.div<{ isMobile: boolean }>`
	display: flex;
	width: 100%;
	flex-direction: column;
	${slickStyles}
`

export const SlidesWrapper = styled.div`
	overflow: hidden;

	@media (max-width: 721px) {
		width: calc(100% + 32px);
		margin: 0 -16px;
	}
`

const ControlsBaseStyles = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 24px;

	@media (min-width: 706px) {
		flex-direction: row;
		margin: 0;
		flex: 2;
		align-items: center;
	}
`

export const Controls = styled.div`
	${ControlsBaseStyles}
	display: none;

	@media ${theme.breakpoints.sm} {
		display: flex;
	}
`

export const MobileControls = styled.div`
	${ControlsBaseStyles}

	@media ${theme.breakpoints.sm} {
		display: none;
	}
`

export const Progress = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	@media ${theme.breakpoints.lg} {
		margin: 0 24px 0 0;
	}
`

export const ProgressBar = styled.div`
	position: relative;
	height: 2px;
	width: 100%;
	background: ${hexToRgb(theme.colors.white, {
		alpha: 0.4,
		format: 'css'
	})};
`

export const Indicator = styled.div.attrs<{ percentage: number }>(
	({ percentage }) => ({
		style: { width: `${percentage || 0}%` }
	})
)<{ percentage: number }>`
	position: absolute;
	display: inline-block;
	height: 2px;
	max-width: 100%;
	background: ${theme.colors.white};
	transition: 500ms ease-in-out;
`

export const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 24px;

	@media (min-width: 706px) {
		margin: auto 0 auto 24px;
	}

	@media ${theme.breakpoints.lg} {
		flex-direction: row;
		justify-content: flex-start;
		margin: 0;
	}

	button {
		svg {
			transition-duration: 0.1s !important;
		}

		:first-child {
			margin-right: 16px;
		}
	}
`

export const StyledContainer = styled(Container)`
	padding: 0;
`

export const StyledSlickCarousel = styled(SlickCarousel)`
	margin-bottom: 48px;

	.slick-track {
		min-width: 100%;
	}

	@media ${theme.breakpoints.lg} {
		margin-bottom: 0;
	}

	.slick-slide {
		width: 312px;
		margin-left: 0;
		margin-right: ${theme.layout.spacingXL};

		&:not(:first-of-type) {
			margin-left: ${theme.layout.spacingXL};
			margin-right: ${theme.layout.spacingXL};
		}
	}
`
