import { theme } from '@damen/ui'
import { hexToRgb } from '@utils/helper'
import styled from 'styled-components'

export const Wrapper = styled.ul`
	display: flex;
	flex-wrap: wrap;

	@media ${theme.breakpoints.sm} {
		flex-wrap: nowrap;
		column-gap: 40px;
		margin-top: 56px;
	}
`

export const Tile = styled.li`
	background-color: ${hexToRgb(theme.colors.blueIce, {
		alpha: 0.05,
		format: 'css'
	})};
	border-radius: 5px;
	width: calc(50% - 8px);
	min-height: 78px;
	margin-bottom: 16px;
	padding: 18px 16px;
	transition: 0.2s ease-in-out;
	font-size: ${theme.typography.fontSizeTextSmall}px;

	&:nth-child(even) {
		margin-left: 8px;
	}

	&:nth-child(odd) {
		margin-right: 8px;
	}

	svg {
		margin-right: 18px;
		opacity: 0.5 !important;
	}

	&:hover,
	&:focus-within {
		transform: scale(1.05);
		background-color: ${theme.colors.marineBlack};
		box-shadow: 10px 20px 40px 0
			${hexToRgb(theme.colors.marineBlack, { alpha: 0.3, format: 'css' })};

		svg {
			opacity: 1 !important;
		}
	}

	a {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;
		text-decoration: none;
	}

	@media ${theme.breakpoints.sm} {
		width: 100%;
		min-height: 160px;
		font-size: ${theme.typography.fontSizeText}px;

		&:nth-child(even) {
			margin-left: 0;
		}

		&:nth-child(odd) {
			margin-right: 0;
		}

		a {
			flex-direction: column;
			justify-content: center;
		}

		svg {
			width: 28px !important;
			height: 29px !important;
			margin-right: 0;
		}
	}
`

export const TileTitle = styled.span`
	color: ${theme.colors.white};

	@media ${theme.breakpoints.sm} {
		margin-top: 24px;
	}
`
