/* eslint-disable @typescript-eslint/no-unused-vars */
// This is the only place we want to use next/image as this correctly wraps
// next/image to handle/force the user to use relative cloudinary image urls
// but still support additional transforms via the loader infrastructure
// eslint-disable-next-line no-restricted-imports
import React from 'react'
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageLoader, ImageProps } from 'next/image'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Transformations {
	// TODO
}

const isStoryblokUrl = (src: string) =>
	src.startsWith('https://a.storyblok.com')

const storyblokLoader =
	(_data: Transformations): ImageLoader =>
	({ src, width }) =>
		`${src}/m/${width}x0`

const getLoader = (
	src: ImageProps['src'],
	options: Transformations
): { src: ImageProps['src']; loader: ImageLoader | undefined } => {
	if (typeof src === 'string' && isStoryblokUrl(src)) {
		return {
			src,
			loader: storyblokLoader(options)
		}
	}

	// undefined means use the default next/image loader (e.g static image data or NOT cloudinary)
	return { src, loader: undefined }
}

type Props = Omit<ImageProps, 'loader' | 'alt'> &
	Partial<Pick<ImageProps, 'alt'>> &
	Transformations

const Image = ({ src, ...others }: Props) => {
	// The Next.JS loader infrastructure always expects a 'relative' url so we override it in case of a custom loader
	// When its the default Next.JS loader we always supply the full url so Next/Image can proxy the request
	const { src: overridenSrc, loader } = getLoader(src, others)

	// eslint-disable-next-line jsx-a11y/alt-text
	return (
		<NextImage
			{...others}
			alt={others.alt ?? ''}
			src={overridenSrc}
			loader={loader}
		/>
	)
}

export default Image
