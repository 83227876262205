import styled from 'styled-components'

import { theme } from '@damen/ui'

import { Title as TitleElement } from '@components/GenericOverview/styles'

import { hexToRgb } from '@utils/helper'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 20px;
		right: 20px;
		height: 40px;
		background: linear-gradient(
			180deg,
			${hexToRgb(theme.colors.white, {
					alpha: 0,
					format: 'css'
				})}
				0%,
			${hexToRgb(theme.colors.white, {
					alpha: 1,
					format: 'css'
				})}
				100%
		);
		bottom: 0;
		z-index: 2;
	}
`
export const Title = styled(TitleElement)`
	margin: 0;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 10px 32px 0;
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 20px;
		right: 20px;
		height: 40px;
		background: linear-gradient(
			180deg,
			${hexToRgb(theme.colors.white, {
					alpha: 1,
					format: 'css'
				})}
				0%,
			${hexToRgb(theme.colors.white, {
					alpha: 0,
					format: 'css'
				})}
				100%
		);
		top: 100%;
		z-index: 2;
	}

	@media ${theme.breakpoints.md} {
		padding: 20px 64px 10px;
	}
`

export const Content = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: auto;
	padding: 20px 32px;
	position: relative;

	@media ${theme.breakpoints.md} {
		padding: 40px 64px;
	}
`

export const StatusItem = styled.div`
	margin: 0 48px 48px 0;

	p {
		font-size: 14px;
		font-weight: 400;
		padding-top: 14px;
	}
`
export const StatusItemTitle = styled.strong`
	margin-bottom: 12px;
`
