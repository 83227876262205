import React, { useCallback, useEffect } from 'react'
import Image from '@components/Image'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import {
	SettingsDocument,
	UpdateUserSettingRequest,
	useGetFavouriteAssetsQuery,
	useSettingsQuery,
	useUpdateUserSettingMutation
} from '@graphql/graphql'

import { Icon, Button, theme } from '@damen/ui'

import { useNotificationContext } from '@contexts/NotificationContext'
import { NotificationTypes } from '@contexts/NotificationContext/types'

import { AssetFavouriteToggle } from '@components/AssetOverview/components/AssetFavouriteToggle'
import Slider from '@components/UI/Slider'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'
import ErrorState from '@components/UI/Error'

import { hexToRgb } from '@utils/helper'

import { useRouter } from 'next/router'
import { goToAssetDetail } from './helpers'
import {
	CardTitle,
	CardHeader,
	DisableWrapper,
	FavouriteWrapper,
	InfoDescription,
	InfoTitle,
	InfoWrapper,
	StyledCard,
	Title,
	IconWrapper
} from './styles'
import { AssetOverviewFavouritesProps } from './types'

const determineShowFavouritesState = (value: boolean | undefined) => {
	switch (value) {
		case true:
			return 'enabled'
		case false:
			return 'disabled'
		default:
			return 'default'
	}
}

const AssetOverviewFavourites = ({ blok }: AssetOverviewFavouritesProps) => {
	const {
		title,
		infoTitle,
		infoDescription,
		disableTitle,
		disableActionTitle,
		errorDescription,
		successDescription,
		errorLoadingFavourites
	} = blok
	const router = useRouter()

	const [updateUserSettingMutation] = useUpdateUserSettingMutation()

	const { data: userSettings, loading: isLoadingUserSettings } =
		useSettingsQuery()

	const determinedShowFavourites = determineShowFavouritesState(
		userSettings?.settings?.showAssetFavourites
	)

	const showFavourites = ['default', 'enabled'].includes(
		determinedShowFavourites
	)
	const {
		data: favourites,
		loading: isLoadingFavourites,
		error: errorFavourites
	} = useGetFavouriteAssetsQuery({
		skip: isLoadingUserSettings
		// TODO: In future we want to optimiz this but we need to manually update the cache
		// showFavorites === 'disabled'
	})

	const isLoading = isLoadingUserSettings || isLoadingFavourites

	const { sendNotification } = useNotificationContext()

	// Event handlers
	const disableFavouritesClickHandler = useCallback(async () => {
		try {
			const updateRequest: UpdateUserSettingRequest = {
				showAssetFavourites: false
			}

			const updateReq = await updateUserSettingMutation({
				variables: {
					request: updateRequest
				},
				refetchQueries: [{ query: SettingsDocument }]
			})

			if (updateReq.data) {
				sendNotification(NotificationTypes.SNACKBAR, {
					snackbarType: SnackbarNotificationTypes.SUCCESS,
					title: successDescription
				})
			}
		} catch (error) {
			sendNotification(NotificationTypes.SNACKBAR, {
				snackbarType: SnackbarNotificationTypes.ERROR,
				title: errorDescription
			})
		}
	}, [
		errorDescription,
		sendNotification,
		successDescription,
		updateUserSettingMutation
	])

	const hasFavourites = favourites?.favouriteAssets?.length

	useEffect(() => {
		if (isLoading) {
			return
		}

		if (determinedShowFavourites !== 'enabled' && hasFavourites > 0) {
			updateUserSettingMutation({
				variables: {
					request: {
						showAssetFavourites: true
					}
				},
				refetchQueries: [{ query: SettingsDocument }]
			})
		} else if (
			determinedShowFavourites === 'enabled' &&
			hasFavourites === 0
		) {
			updateUserSettingMutation({
				variables: {
					request: {
						showAssetFavourites: false
					}
				},
				refetchQueries: [{ query: SettingsDocument }]
			})
		} else if (
			determinedShowFavourites === 'disabled' &&
			hasFavourites > 0
		) {
			updateUserSettingMutation({
				variables: {
					request: {
						showAssetFavourites: true
					}
				},
				refetchQueries: [{ query: SettingsDocument }]
			})
			// Force enable
		}
	}, [
		hasFavourites,
		isLoading,
		showFavourites,
		determinedShowFavourites,
		updateUserSettingMutation
	])

	if (isLoading) {
		return (
			<FavouriteWrapper>
				<Title>
					<SkeletonLoader width={200} />
				</Title>
				<StyledCard style={{ maxWidth: 312 }}>
					<CardHeader>
						<SkeletonLoader width={21} height={21} />
					</CardHeader>
					<SkeletonLoader width={218} height={191} />
					<CardTitle>
						<SkeletonLoader />
					</CardTitle>
				</StyledCard>
			</FavouriteWrapper>
		)
	}

	if (errorFavourites) {
		return (
			<>
				<Title>{title}</Title>
				<ErrorState
					content={errorLoadingFavourites}
					variant="white"
					showBowWave
				/>
			</>
		)
	}

	//  No favourites - show prompt
	if (showFavourites && (!hasFavourites || hasFavourites === 0)) {
		return (
			<FavouriteWrapper>
				<Title>{title}</Title>
				<InfoWrapper>
					<InfoTitle>{infoTitle}</InfoTitle>
					<InfoDescription>
						{infoDescription}{' '}
						<Icon.Star
							width={17}
							height={17}
							fill={theme.colors.white}
						/>
					</InfoDescription>
				</InfoWrapper>
				<DisableWrapper>
					{disableTitle}
					<Button.Default
						text={disableActionTitle}
						onClick={disableFavouritesClickHandler}
						colorType="blue"
						isSmall
						icon={Icon.LeftChevron}
					/>
				</DisableWrapper>
			</FavouriteWrapper>
		)
	}

	// Show favourites
	if (showFavourites && hasFavourites > 0) {
		return (
			<FavouriteWrapper>
				<Slider key={favourites.favouriteAssets.length} title={title}>
					{favourites.favouriteAssets.map((asset) => {
						const key = `${asset.name}-${asset.id}`

						const imageUrl = asset?.images?.threeDimensional

						return (
							<StyledCard
								key={key}
								onClick={goToAssetDetail(router, asset)}
							>
								<CardHeader>
									<AssetFavouriteToggle
										isFavourite
										assetId={asset?.id}
										iconWidth={21}
										iconHeight={21}
									>
										<Icon.Star
											width={21}
											height={21}
											fill={theme.colors.white}
										/>
									</AssetFavouriteToggle>
								</CardHeader>
								{imageUrl && (
									<Image
										alt={asset.name}
										src={imageUrl}
										width={218}
										height={191}
									/>
								)}
								<IconWrapper hasImage={imageUrl?.length > 0}>
									<Icon.BowWave
										width={218}
										height={198}
										fill={`${hexToRgb(theme.colors.white, {
											alpha: 0.05,
											format: 'css'
										})}`}
									/>
								</IconWrapper>
								<CardTitle>{asset.name}</CardTitle>
							</StyledCard>
						)
					})}
				</Slider>
			</FavouriteWrapper>
		)
	}

	return null
}

export default AssetOverviewFavourites
