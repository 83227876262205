import styled, { keyframes } from 'styled-components'
import { theme } from '@damen/ui'

import ContainerComponent from '@components/Container/Container'
import { SortIconTextProps } from '@components/UI/SortIcon/types'

export const Container = styled(ContainerComponent)`
	padding-bottom: 40px;
`
export const PaginationWrapper = styled.div<{
	layout?: 'infinite' | 'default'
}>`
	display: flex;
	flex-direction: ${({ layout }) =>
		layout === 'infinite' ? 'column' : 'row'};
	align-items: ${({ layout }) =>
		layout === 'infinite' ? 'center' : 'flex-end'};
	justify-content: ${({ layout }) =>
		layout === 'infinite' ? 'center' : 'flex-end'};
	margin-top: 32px;
`

const spinnerRotation = keyframes`
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
`

export const LoadingText = styled.span`
	display: block;
	margin-top: ${theme.layout.spacingM};
	color: ${theme.colors.grey};
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeTextSmall}px;
`

export const SpinnerWrapper = styled.div`
	svg {
		animation: ${spinnerRotation} ${theme.timing.slow} infinite;
	}
`

export const Title = styled.h2`
	margin-top: 18px;
	margin-bottom: 56px;
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeHeadingFour}px;
	color: ${theme.colors.marineBlack};
`

export const TableWrapper = styled.div`
	margin-top: 40px;
`

export const OverviewTitle = styled.h2`
	margin-top: 94px;
	margin-bottom: ${theme.layout.spacing};
	font-size: ${theme.typography.fontSizeHeadingTwo}px;
	color: ${theme.colors.marineBlack};
`

export const TableIconWrapper = styled.span`
	margin-left: 8px;
	display: inline-block;
	position: relative;
	z-index: 1;
`

export const SortIconWrapper = styled.div`
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 8px 40px 8px 0;
	display: flex;
	align-items: center;
`

export const SortIconText = styled.span<SortIconTextProps>`
	position: relative;
	line-height: 32px;
	visibility: hidden;
	padding-right: ${({ hasIcon }) => (hasIcon ? '24px' : '0')};
`

export const SortIconAsc = styled.span`
	margin: auto 0 auto 16px;

	svg {
		transform: rotate(-90deg);
	}
`

export const SortIconDesc = styled.span`
	margin: auto 0 auto 16px;

	svg {
		transform: rotate(90deg);
	}
`

export const ButtonLink = styled.button`
	display: flex;
	align-items: center;
	padding: 0;
	margin-top: 32px;
	font-family: ${theme.fonts.body};
	font-size: ${theme.typography.fontSizeTextSmall}px;
	line-height: 14px;
	background: transparent;
	border: none;
	color: ${theme.colors.blue};

	svg {
		margin-left: 10px;
	}

	&:hover,
	&:focus {
		text-decoration: underline;
		cursor: pointer;
	}
`
